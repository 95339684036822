footer.content-info {
	.footer-menu {
		background-color: $gray;
		width: auto;
		position: relative;
		padding: 40px 0px 20px 0px;
		@include media-breakpoint-up(xs) {
			height: auto;
		}
		.widget {
			margin: 0 auto;
			justify-content: center;
			display: inline-flex;
			ul.menu {
				@extend .list-unstyled;
				li {
					line-height: 35px;
				}
			}
			a {
				color: $white;
				text-transform: uppercase;
				font-family: 'GothamRounded-Light';
				@include media-breakpoint-up(xs) {
	              font-size: 16px;
	            }
	            @include media-breakpoint-up(sm) {
	              font-size: 15px;
	            }
			}
			ul.sub-menu {
				@extend .list-unstyled;
				li.menu-item {
					a {
						text-transform: none;
						padding-left: 25px;
					}
				}
			}
		}
	}
	.sub-footer {
		background-color: $brand-primary;
		color: $white;
		.info {
			@include media-breakpoint-up(xs) {
              text-align: center;
            }
            @include media-breakpoint-up(md) {
              text-align: left;
            }
		}
		p {
            @include media-breakpoint-up(xs) {
              font-size: 14px;
              text-align: center;
            }
            @include media-breakpoint-up(md) {
              display: inline;
              padding-top: 15px;
            }
            @include media-breakpoint-up(lg) {
              font-size: 16px;
            }
            span.separator {
            	@include media-breakpoint-down(sm) {
	            	display: none;
	            }
            }
            span.break {
            	@include media-breakpoint-down(xs) {
	            	display: none;
	            }
            }
            a {
            	color: $white;
            }
        }
		.thomson-logo {
			img {
	            position: relative;
	            @include media-breakpoint-up(md) {
	              left: 30%;
	              padding: 25px;
	            }
	        }
		}
		.giant-hat-logo {
			img {
				width: 50%;
				height: auto;
				@include media-breakpoint-up(sm) {
	                width: 40%;
	            }
	            @include media-breakpoint-up(sm) {
	                width: 50%;
	            }
			}
		}
	}
}

footer.content-info .footer-menu .widget ul.menu li {
	@include media-breakpoint-down(xs) {
        text-align: center;
    }
}