/* HOMEPAGE
   ========================================================================== */
#home-banners {
	min-height: 450px;
	height: 50vh;
	background-size: cover;
	// background-image: url(../images/print-page-main.png);
	// background-image: url(../images/Happy-Holidays.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	overflow: visible;
	width: 100%;
	position: relative;

	.tagline {		
		background-color: $brand-primary;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	h1 {
		color: $white;
		font-family: 'GothamRounded-Light';
		margin-top: auto;
		margin-bottom: auto;

		@include media-breakpoint-up(xs) { 
    	font-size: 2em;
    }

    @include media-breakpoint-up(md) { 
    	font-size: 2.5em;
    }
	}

	.caption {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
	}

	p {
		color: $gray;
		font-size: 14px;
	}
}

#services {
	padding-top: 5px;

	.col-sm-4 {
		@include media-breakpoint-down(sm) { 
    	margin-bottom: 40px;
    }
	}

	.col-md-3 {
		img {
			position: relative;
			z-index: 1000;
			height: 100%;

			@include media-breakpoint-up(xs) { 
	  		padding: 10px 0px;
      }

      @include media-breakpoint-up(lg) { 
	  		padding: 0px;
	  		left: -10px;
      }

      @include media-breakpoint-up(xl) { 
	  		padding: 0px;
	  		left: 7px;
      }
		}
	}

	a {
		text-decoration: none;
	}

	.services {
		background-color: #6c6c6c;
		color: $white;
		position: relative;

		h5 {
			@include media-breakpoint-up(xs) { 
				padding: 15px 0px;
			}

			@include media-breakpoint-up(lg) { 
				margin: 0;
		    position: absolute;
		    top: 50%;
		    transform: translate(0, -50%);
		    padding: 0px;
		    font-size: 1.4vw;
			} 
		}
	}

	.digital-printing-image {
		z-index: 1000;

		@include media-breakpoint-up(xs) { 
			background-color: #10679c;
    }

    @include media-breakpoint-up(lg) { 
			background-color: #6c6c6c;
    }
	}

	.offset-printing-image {
		z-index: 1000;

		@include media-breakpoint-up(xs) { 
			background-color: #c25e5e;
    }

		@include media-breakpoint-up(lg) { 
			background-color: #10679c;
    }
	}

	.creative-services-image {
		z-index: 1000;

		@include media-breakpoint-up(xs) { 
			background-color: #f8c105;
    }

		@include media-breakpoint-up(lg) { 
    	background-color: #c25e5e;
    }
	}

	.digital-printing,
	.offset-printing,
	.creative-services {
		position: relative;

		h3 {
			font-family: 'Gotham-Book';
			color: $gray;

			@include media-breakpoint-up(lg) { 
      	padding-left: 25px;
      }
		}

		h5 {
			color: $white;
			padding-top: 5px;
			//padding-top: 30px;

			@include media-breakpoint-up(xs) { 
				padding-top: 15px;
				text-align: center;
			}

			@include media-breakpoint-up(md) { 
				padding-top: 15px;
				text-align: left;
			}

			@include media-breakpoint-up(lg) { 
      	padding-left: 78px;
      	text-align: center;
      }
		}

		p {
			color: $white;

			@include media-breakpoint-up(xs) {
				position: relative;
				text-align: center;
			}

			@include media-breakpoint-up(md) { 
				margin: 0;
		    position: absolute;
		    top: 50%;
		    transform: translate(0, -50%);
      	font-size: 1.6vw;
      	text-align: left;
	    }

			@include media-breakpoint-up(lg) { 
      	font-size: 1vw;
      	padding-left: 78px;
      	position: relative;
      	top: 0;
      	transform: none;
      	text-align: center;
      }
		}
	}

	.digital-printing {
		background-color: #10679c;
	}

	.offset-printing {
		background-color: #c25e5e;
	}

	.creative-services {
		background-color: #f8c105;
	}
}

section#family-owned {
	padding-top: 45px;

	h1 {
		color: #6c6c6c;
		font-family: 'Gotham-Book';
	}

	h5 {
		color: #6c6c6c;
		font-family: 'Gotham-Book';
	}
}

section#single-page-testimonials {
	position: relative;
	width: 100%;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	background-color: $brand-primary;
	margin-top: 100px;
	margin-bottom: 100px;
	padding-top: 15px;
	top: 20px;

	.single-testimonial {
		img {
	  	border: 10px solid $brand-primary;
      left: 0;  
      width: auto;
	  	border-radius: 50%;

      @include media-breakpoint-up(xs) { 
      	position: relative;
      	height: 270px;
      }

      @include media-breakpoint-up(sm) { 
        position: absolute;
        height: 150px;
		  	top: 50%;
		  	transform: translateY(-50%);
      }

      @include media-breakpoint-up(md) { 
        height: 230px;
      }

      @include media-breakpoint-up(lg) { 
        height: 270px;
      }
		}

		p {
			color: $white;
		}
	}
}

#join {
	background-size: cover;
	background-image: url(../images/iphone.png);
	background-repeat: no-repeat;
	background-position: center center;
	overflow: visible;
	width: 100%;
	position: relative;
	padding: 20px 0px;

	.form-control {
		width: 100%;
		position: relative;
		top: 10px;
	}

	button.btn {
		position: relative;
		top: 10px;

		@include media-breakpoint-up(xs) { 
      width: 100%;
    }

    @include media-breakpoint-up(sm) { 
      width: auto;
    }
	}

	h3 {
		position: relative;
		top: 10px;

		@include media-breakpoint-up(xs) { 
	    padding-top: 20px;
	  }

    @include media-breakpoint-up(sm) { 
      padding-top: 0px;
      font-size: 1.95em;
    }
	}
}

#our-testimonials {
	padding: 60px 0px 30px 0px;

	h2 {
		color: $gray;
		font-family: 'Gotham-Book';
	}

	h6 {
		color: $gray;
		font-family: 'Gotham-Book';
		padding-bottom: 30px;
	}

	.container {
		position: relative;

		.john {
			img {
				position: relative;
				border-radius: 50%;
				border: 10px solid $brand-primary;

				@include media-breakpoint-up(xs) { 
          width: 100%;
          margin-bottom: 30px;
        }

        @include media-breakpoint-up(sm) { 
          width: 120%;
          left: 42px;
        }

        @include media-breakpoint-up(md) { 
          width: 120%;
          left: 30px;
        }
			}
		}

		.jackie {
			img {
				position: relative;
				border-radius: 50%;
				border: 10px solid $gray;

				@include media-breakpoint-up(xs) { 
          width: 100%;
          margin-bottom: 30px;
        }

        @include media-breakpoint-up(sm) { 
          width: 140%;
          left: 15px;
        }

        @include media-breakpoint-up(md) { 
          width: 140%;
          left: 0px;
        }
			}
		}

		.diane {
			img {
				position: relative;
				z-index: 100;
				border-radius: 50%;
				border: 10px solid $brand-orange;

				@include media-breakpoint-up(xs) { 
          width: 100%;
          margin-bottom: 30px;
        }

        @include media-breakpoint-up(sm) { 
          width: 160%;
        }

        @include media-breakpoint-up(md) { 
          left: -15px;
        }
			}
		}

		.jim {
			img {
				width: 140%;
				border-radius: 50%;
				border: 10px solid $brand-blue;
				position: relative;
				z-index: 50;

				@include media-breakpoint-up(xs) { 
          width: 100%;
          margin-bottom: 30px;
        }

        @include media-breakpoint-up(sm) { 
          width: 140%;
        }
			}
		}

		.mary {
			img {
				border-radius: 50%;
				border: 10px solid $brand-yellow;
				position: relative;
				z-index: 2;

				@include media-breakpoint-up(xs) { 
          width: 100%;
          margin-bottom: 30px;
        }

        @include media-breakpoint-up(sm) { 
          width: 120%;
          left: -15px;
        }

        @include media-breakpoint-up(md) { 
          width: 120%;
          left: 0px;
        }
			}
		}

		.jon {
			img {
				position: relative;
				border-radius: 50%;
				border: 10px solid $brand-primary;

				@include media-breakpoint-up(xs) { 
          width: 100%;
          margin-bottom: 30px;
        }

        @include media-breakpoint-up(sm) { 
          display: none;
        }
			}
		}
	}
}

/* Page Layout
   ========================================================================== */
.page-header {
	background-color: $brand-primary;
	padding: 10px 0;
}

.page-header-krilogy {
	background-color: $krilogy;
	padding: 10px 0;
}

.page-header-reach {
	background-color: $reach-blue;
	padding: 10px 0;
}

body.reach-ltc section#join {
	display: none;
}

img.krilogy-logo {
	max-width: 350px !important;
	padding: 25px 0 35px 0;
}

img.reach-logo {
	max-width: 350px !important;
	padding: 25px 0 35px 0;
}

.background {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 450px;

	h1 {
		color: $white;
		margin: 0;
		font-family: 'Gotham-Book';
	}

	img {
		position: relative;
		max-width: 100%;
	}
}

.parent-pageid-12 {
	.background {
		background-image: none !important;
	}
}

.page-text {
	.page-content {
		padding-top: 20px;
		h1 {
			color: $gray;
		}
		p {
			color: $gray;
		}
	}
}

/* Testimonial Archive Layout
   ========================================================================== */
.testimonial-background {
	background-size: cover;
	background-image: url(../images/testimonial_background.png);
	background-repeat: no-repeat;
	background-position: center center;
	overflow: visible;
	width: 100%;
	position: relative;
	padding: 20px 0px;

	h1 {
		background-color: $brand-primary;
		color: $white;
	}
}

time {
	display: none;
}

p.author {
	display: none;
}

#posts {
	h1,
	h2,
	h3,
	h4 {
		padding-top: 35px;
	}

	article.industry-creative-services, 
	article.industry-software-development, 
	article.industry-cms-development, 
	article.industry-commercial-printing,
	article.industry-history {
		width: 100%;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		margin-top: 100px;
		margin-bottom: 100px;
		padding-top: 15px;

		img {
	  	left: 0;  
	  	top: 50%;
	  	transform: translateY(-50%);
	  	border-radius: 50%;
      width: auto;

      @include media-breakpoint-up(xs) { 
      	position: relative;
      	height: 270px;
      }
      @include media-breakpoint-up(sm) { 
        position: absolute;
        height: 150px;
      }
      @include media-breakpoint-up(md) { 
        height: 230px;
      }
      @include media-breakpoint-up(lg) { 
        height: 270px;
      }
		}

		h2 {
			a {
				color: $white;
			}
		}

		.entry-summary {
			p {
				color: $white;
			}
		}
	}

	article.industry-creative-services {
		background-color: $brand-yellow;

		img {
			border: 10px solid $brand-yellow;
		}
	}

	article.industry-software-development {
		background-color: $brand-blue;

		.testimonial-image {
			img {
			  	border: 10px solid $brand-blue;
			}
		}
	}
	article.industry-cms-development {
		background-color: $brand-orange;

		.testimonial-image {
			img {
			  	border: 10px solid $brand-orange;
			}
		}
	}

	article.industry-commercial-printing {
		background-color: $gray;

		.testimonial-image {
			img {
			  	border: 10px solid $gray;
			}
		}
	}

	article.industry-history {
		background-color: $brand-primary;

		.testimonial-image {
			img {
			  	border: 10px solid $brand-primary;
			}
		}
	}

	.odd {
		article.testimonial {
			.container {
				.row {
					.testimonial-image {
						img {
							right: 100px;
						}
					}
				}
			}
		}
	}
}

/* Templates Layout
   ========================================================================== */
.creative-background, 
.cms-background,
.software-background,
.commercial-printing-background,
.history-background {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 450px;

	.col-sm-12 {
		padding-right: 0px;
		padding-left: 0px;

		h1 {
			color: $white;
			padding: 5px 0px 5px 30px;
			font-family: 'Gotham-Book';
		}

		img {
			position: relative;

			@include media-breakpoint-up(xs) { 
        width: 90%;
      }

      @include media-breakpoint-up(sm) { 
        width: auto;
      }
		}
	}
}

.creative-page-text,
.cms-page-text,
.software-page-text,
.commercial-printing-page-text,
.history-page-text {
	.page-content {
		padding-top: 20px;

		h1{
			padding-bottom: 20px;
			font-family: 'GothamRounded-Light';
		}

		h2 {
			font-weight: bold;
			padding-top: 20px;
			font-family: 'GothamRounded-Medium';
		}

		p {
			color: $gray;
		}
	}
}

section#creative-services-testimonials,
section#cms-testimonials,
section#software-testimonials,
section#commercial-printing-testimonials,
section#history-testimonials {
	width: 100%;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	margin-top: 100px;
	margin-bottom: 100px;
	padding-top: 15px;

	img {
		position: absolute;
  	left: 0;  
  	top: 50%;
  	transform: translateY(-50%);
  	border-radius: 50%;
  	height: 270px;
    width: auto;

    @include media-breakpoint-up(xs) { 
    	position: relative;
    	height: 270px;
    }
    @include media-breakpoint-up(sm) { 
      position: absolute;
      height: 200px;
    }
    @include media-breakpoint-up(md) { 
      height: 270px;
    }
	}

	p {
		color: $white;
	}
}

#creative-services,
#cms-services,
#software-services,
#commercial-printing-services,
#history-services {
	ul {
		list-style: none
	}

	li::before {
		content: "•"; 
		display: inline-block; 
		width: 1em;
		margin-left: -1em
	}
}

/* Creative Services Template
   ========================================================================== */

.page.creative-services {
	.page-header {
		background-color: $brand-yellow;
	}
}

.creative-page-text {
	.page-content {
		h1{
			color: $brand-yellow;
		}

		h2 {
			color: $brand-yellow;
		}
	}
}

section#creative-services-testimonials {
	background-color: $brand-yellow;

	img {
	  	border: 10px solid $brand-yellow;
	}
}

#creative-services {
	li::before {
		color: $brand-yellow;
	}
}

/* CMS Development Template
   ========================================================================== */

.page.cms-development {
	.page-header {
		background-color: $brand-orange;
	}
}

.cms-page-text {
	.page-content {
		h1{
			color: $brand-orange;
		}

		h2 {
			color: $brand-orange;
		}
	}
}

section#cms-testimonials {
	background-color: $brand-orange;

	img {
	  border: 10px solid $brand-orange;
	}
}

#cms-services {
	li::before {
		color: $brand-orange;
	}
}


/* Software Development Template
   ========================================================================== */
.page.software-development {
	.page-header {
		background-color: $brand-blue;
	}
}

.software-page-text {
	.page-content {
		h1{
			color: $brand-blue;
		}

		h2 {
			color: $brand-blue;
		}
	}
}

section#software-testimonials {
	background-color: $brand-blue;

	img {
	  border: 10px solid $brand-blue;
	}
}

#software-services {
	li::before {
		color: $brand-blue;
	}

}

/* Commercial Printing Template
   ========================================================================== */
.page.commercial-printing {
	.page-header {
		background-color: $gray;
	}
}
.commercial-printing-page-text {
	.page-content {
		h1{
			color: $gray;
		}

		h2 {
			color: $gray;
		}
	}
}

section#commercial-printing-testimonials {
	background-color: $gray;

	img {
	  	border: 10px solid $gray;
	}
}

#commercial-printing-services {
	li::before { 
		color: $gray;
	}
}

/* History Template
   ========================================================================== */
.page.our-history {
	.page-header {
		background-color: $brand-primary;
	}
}

.history-page-text {
	.page-content {
		h1{
			color: $brand-primary;
		}

		h2 {
			color: $brand-primary;
		}
	}
}

section#history-testimonials {
	background-color: $brand-primary;

	img {
	  	border: 10px solid $brand-primary;
	}
}

#history-services {
	li::before {
		color: $brand-primary;
	}
}


/* Formstack
   ========================================================================== */
.fsPage {
	min-height: 0px;
}
