/* ==========================================================================
   HOMEPAGE BANNERS
   ========================================================================== */
  .single-banner {
  	background-size: cover;
  	background-position: center center;
  	background-repeat: no-repeat;
  	.single-banner-copy {
  		background-color: rgba(0,0,0,0.5);
  		color: white;
  		p {
  			margin: 0;
  		}
  	}
  }
  .resize {
  	height: 80vh;
  }
  .resize-50 {
    height: 50vh;
  }