/* ==========================================================================
   PRIMARY NAV
   ========================================================================== */
  header.banner {
      .super-header {
        background-color: $gray;
        color: $white;
        padding-top: 15px;
        padding-bottom: 15px;
        height: auto;
        overflow: visible;
        a {
          color: white;
          &:hover {
            color: #ddd;
            text-decoration: none;
          }
        }
        p {
          margin-bottom: 0;
          display: inline-block;
          @include media-breakpoint-up(xs) {
            font-size: 12px;
          }
          @include media-breakpoint-up(lg) {
            font-size: 14px;
          }
        }
        .fa {
          display: inline-block;
          padding-right: 5px;
        }
        .contact-container {
          @include media-breakpoint-up(md) { 
            padding-right: 15px;
          }
        }
        .contact-phone,
        .contact-email {
          display: inline-block;
        }
        .contact-phone {
          i.fa-phone {
            padding-top: 3px;
          }
          p {
            margin-top: -3px;
            font-size: 12px;
            margin-right: 15px;
            @include media-breakpoint-down(md) { 
              display: none;
            }
          }
        }
        .contact-email {
          i.fa-envelope-o {
          }
          p {
            margin-top: -3px;
            font-size: 12px;
            @include media-breakpoint-down(md) { 
              display: none;
            }
          }
        }
      }
      nav.nav-primary {
        background-color: $brand-primary;
        width: 100%;
        min-height: 45px;
        .nav-link, .nav li {
            @extend .nav-item;
            a {
              color: $white;
              text-align: center;
              font-family: 'GothamRounded-Light';
              @extend .nav-link;
            @include media-breakpoint-up(lg) {
              font-size: 13px;
            }
            @include media-breakpoint-up(xl) {  
              font-size: 16px;
            }
          }
        }
      .navbar-light .navbar-toggler {
        z-index: 10;
        color: white;
        top: 2rem;
        background-color: $brand-primary;
      }
    }
    .nav-primary li {
       position: relative;
       &:hover {
          > .sub-menu {
              display: block;
              @include media-breakpoint-up(lg) {  
                width: auto;
              }
              li a {
                border-bottom: 1px solid $white;
                font-size: 1em; 
                &:hover, &:active {
                  background: $brand-primary;
                  color: $white;
                  width: 100%;
                }
              }
          }
      }
      > .sub-menu {
          display: none;
          list-style: none outside none;
          background: $brand-primary;
          font-size: 0.8em;
          position: absolute;
          padding-left: 0px;
          left: 0;
          z-index: 11000;
          width: 100%;
          > li {
              display: block;
              padding: 0;
              a {
                display: block;
                padding: 10px;
                text-decoration: none !important;
                text-align: center;
                color: $white;
              }
          }
      }
    }
}
 
  .nav {
    @extend .justify-content-center;
  	li {
  		@extend .nav-item;
  		&.active {
  			a {
  			}
  		}
  		a {
  			@extend .nav-link;
  		}
  	}
  }

   // .fa-coffee {
   //    color: $white;
   //    position: relative;
   //    top: 4px;
   //    a {
   //      font-weight: bold;
   //      transform: translate(0, -50%);
   //    }
   //  }