// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// ==========================================================================
// Gravity Forms
// ==========================================================================
.gform_wrapper .gform_body .gform_fields .gsection {
  border-bottom: none;
}

.gform_wrapper .gform_body ul {
  padding: 0;
  @extend .list-unstyled;

  li {
    margin-bottom: 1rem;
  }

  label {
    display: block;
    margin-bottom: 0;
  }
}

.gform_wrapper .gform_body .gform_fields .gfield .ginput_container_name span,
.gform_wrapper .gform_body .gform_fields .gfield .ginput_complex span {
  display: block;
  margin-right: 0;
}

.gform_wrapper .gform_body .gform_fields .gfield .ginput_complex .ginput_left,
.gform_wrapper .gform_body .gform_fields .gfield .ginput_complex .ginput_right {
  padding-right: 0 !important;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.gform_wrapper .gform_body .gform_fields .gfield .ginput_container_name span.name_last,
.gform_wrapper .gform_body .gform_fields .gfield .has_street span.address_zip {
  margin-top: 16px;
}

.gform_wrapper .gform_body .gform_fields .gfield .gfield_label {
  color: $brand-primary;
  font-size: 1.25rem;
  font-weight: 500;
}

.gform_wrapper .gform_body .gform_fields .gfield .gfield_checkbox label {
  color: $gray-light;
  font-size: 13px;
}

.gform_wrapper .gform_body .gform_fields .ginput_container_radio {
  .gfield_checkbox li input[type=checkbox],
  .gfield_radio li input[type=radio] {
    width: 25px;
  }

  label {
    display: inline-block !important;
  }
}

.gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox],
.gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio],
.gform_wrapper.gf_browser_chrome .gfield_checkbox li input {
  margin-top: 0;
  margin-right: 5px;
}

.gform_wrapper .gform_body .gform_fields .gfield select,
.gform_wrapper .gform_body .gform_fields .gfield input,
.gform_wrapper .gform_body .gform_fields .gfield .ginput_complex span.ginput_full input[type=text] {
  @include media-breakpoint-up(xs) {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.gform_wrapper .gform_body .gform_fields .gfield .gfield_select,
.gform_wrapper .gform_body .gform_fields .gfield .has_street select {
  border-radius: 0;
  color: $gray-light;
  font-size: 13px;
  height: auto;
  padding: 10px !important;
  text-transform: uppercase;
}

.gform_wrapper .gform_body .gform_fields .gfield .has_street select {
  width: 100%;
}

.gform_wrapper .gform_body .gform_fields .gfield input {
  color: $gray-light;
  font-size: 13px !important;
  height: auto;
  padding: 10px !important;
}

td.gfield_list_cell input {
  width: 100% !important;
}

.gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
  margin: 0;

  @media only screen and (max-width: 641px) {
    line-height: 1rem !important;
  }
}

.ginput_container_address {
  input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
    margin-bottom: 15px;
  }
}

.ginput_container_fileupload {
  input {
    @extend .btn;
    @extend .btn-outline-primary;
  }
}

.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_footer input[type=submit],
.gform_wrapper .gform_page_footer input[type=submit] {
  @extend .btn;
  @extend .btn-primary;
}

@media only screen and (min-width: 641px) {
  .gform_wrapper .top_label input.medium,
  .gform_wrapper .top_label select.medium {
    width: 100%;
  }
}

.gform_wrapper {
  .gf_progressbar_wrapper {
    margin-bottom: 1rem !important;
    padding-bottom: 3rem;

    h3.gf_progressbar_title {
      font-size: 16px !important;
      margin-left: 0 !important;
      opacity: inherit;
    }

    .gf_progressbar {

      padding: 0 !important;

      &::after {
        height: auto;
      }

      .gf_progressbar_percentage {
        @extend .progress-bar;

        background: $brand-primary !important;
        border-radius: .25rem;
        height: 1.25rem;
        text-align: center !important;

        span {
          float: none;
          font-size: 0.75rem;
        }
      }
    }
  }
}

body.krilogy .gform_wrapper .gform_body .gform_fields .gfield .gfield_label,
body.krilogy .gform_wrapper .gform_body .gform_fields .gfield .gfield_label,
body.krilogy .gform_wrapper span.ginput_total {
  color: $krilogy !important;
}

body.krilogy .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
  background-color: $krilogy !important;
  border-color: $krilogy !important;
}

body.reach-ltc .gform_wrapper .gform_body .gform_fields .gfield .gfield_label,
body.reach-ltc .gform_wrapper .gform_body .gform_fields .gfield .gfield_label,
body.krilogy .gform_wrapper span.ginput_total {
  color: $reach-blue !important;
}

body.reach-ltc .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
  background-color: $reach-blue !important;
  border-color: $reach-blue !important;
}
