h1,
.h1 {
  font-size: 2.5rem;
  font-family: $font-family-sans-serif-book;
  font-weight: 500;
  line-height: 1.1;
}

h2,
.h2 {
  font-size: 2rem;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  line-height: 1.1;
}

h3,
.h3 {
  font-size: 1.7rem;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  line-height: 1.1;
}

h4,
.h4 {
  font-size: 1.5rem;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  line-height: 1.1;
}
