// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


/* ==========================================================================
   COLORS
   ========================================================================== */
  /* Brand Colors
     ========================================================================== */
		$brand-primary:         #6cab1f;
		$brand-orange:					#f57100;
		$brand-blue:						#10679c;
		$brand-yellow:					#f8c105;
		$white:					#FFFFFF;
		$black:					#000000;
    $krilogy:       #1c355e;
    $reach-blue:    #003a60;
    $reach-orange:  #e65925;

	/* Grays
	   ========================================================================== */
		$gray:									#6c6c6c;

	/* Social 
	   ========================================================================== */
		$twitter:     #00aced;
		$facebook:    #3b5998;
		$googleplus:  #dd4b39;
		$pinterest:   #cb2027;
		$linkedin:    #007bb6;
		$youtube:     #bb0000;
		$vimeo:       #aad450;
		$tumblr:      #32506d;
		$instagram:   #517fa4;
		$flickr:      #ff0084;
		$dribbble:    #ea4c89;
		$quora:       #a82400;
		$foursquare:  #0072b1;
		$forrst:      #5B9A68;
		$vk:          #45668e;
		$wordpress:   #21759b;
		$stumbleupon: #EB4823;
		$yahoo:       #7B0099;
		$blogger:     #fb8f3d;
		$soundcloud:  #ff3a00;
		$snap:        #fffc00;

/* ==========================================================================
   TYPOGRAPHY
   ========================================================================== */
	$font-family-serif: Times, serif;
	$font-family-sans-serif: "GothamRounded-Book", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
	$font-family-sans-serif-med: "GothamRounded-Medium", "GothamRounded-Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
	$font-family-sans-serif-light: "GothamRounded-Light", "GothamRounded-Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
	$font-family-sans-serif-book: "Gotham-Book", "Gotham-Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
	$headings-font-family: $font-family-sans-serif;


	@font-face {
		font-family: 'Gotham-Book';
		src: url('../fonts/Gotham-Book.svg#Gotham-Book') format('svg'), 
				 url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
	       url('../fonts/Gotham-Book.woff') format('woff'),
	       url('../fonts/Gotham-Book.ttf') format('truetype'),
	       url('../fonts/Gotham-Book.otf') format('opentype');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'GothamRounded-Book';
		src: url('../fonts/GothamRounded-Book.svg#GothamRounded-Book') format('svg'), 
				 url('../fonts/GothamRounded-Book.eot?#iefix') format('embedded-opentype'),
	       url('../fonts/GothamRounded-Book.woff') format('woff'),
	       url('../fonts/GothamRounded-Book.ttf') format('truetype'),
	       url('../fonts/GothamRounded-Book.otf') format('opentype');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'GothamRounded-Medium';
		src: url('../fonts/GothamRounded-Medium.svg#GothamRounded-Medium') format('svg'), 
				 url('../fonts/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
	       url('../fonts/GothamRounded-Medium.woff') format('woff'),
	       url('../fonts/GothamRounded-Medium.ttf') format('truetype'),
	       url('../fonts/GothamRounded-Medium.otf') format('opentype');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'GothamRounded-Light';
		src: url('../fonts/GothamRounded-Light.svg#GothamRounded-Light') format('svg'), 
				 url('../fonts/GothamRounded-Light.eot?#iefix') format('embedded-opentype'),
	       url('../fonts/GothamRounded-Light.woff') format('woff'),
	       url('../fonts/GothamRounded-Light.ttf') format('truetype'),
	       url('../fonts/GothamRounded-Light.otf') format('opentype');
		font-weight: normal;
		font-style: normal;
	}


/* ==========================================================================
   MIXINs
   ========================================================================== */
  @mixin background-image-retina($file, $type, $width, $height) {
    background-image: url('../../dist/images/'+$file + '.' + $type);
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (-moz-min-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx){
      & {
        background-image: url('../../dist/images/'+$file + '@2x.' + $type);
        -webkit-background-size: $width $height;
        -moz-background-size: $width $height;
        -o-background-size: $width $height;
        background-size: $width $height;
      }
    }
    /**
     * USEAGE
     * .class {
       * width: 200px;
       * height: 100px;
       * @include background-image-retina(logo, png, 200px, 100px);
       * // Image replacement code 
       * display: block; 
       * text-indent: -9999px; 
       * white-space: nowrap; 
       * overflow: hidden; 
     * }
     */
  }